body {
	min-height: 100vh;
	margin: 0;
	background: #f7f8fa 0% 0% no-repeat padding-box;
	font-family: 'Roboto', sans-serif;
	opacity: 1;
}

main {
	min-height: 100vh;
	border: 1px solid #ccc;
}

.center {
	text-align: center;
}

.align-right {
	text-align: right;
}

.login-box {
	padding: 10px;
	border-radius: 10px;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.forgot-link {
	font-size: 12px;
}

.login-btn {
	margin-top: 15px;
	width: 100%;
	height: 40px;
	border-radius: 5px;
	font-size: 16px;
}

.confirm-btn {
	margin: 20px;
	width: 60%;
	height: 55px;
	border-radius: 10px;
	margin-left: 90px;
	color: #ffffff;
	border: 0;

	&:hover {
		color: #ffffff;
	}
}

.img-icon {
	width: 24px;
	margin: 10px;
}
